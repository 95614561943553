// スタイルシートを読み込む
import "./index.scss";

//BootstrapのJavaScript側の機能を読み込む
import "bootstrap";

import "./main.js";


//import Img1 from './img/top.svg';
//import Logo from './img/logo.svg';

// import iconLogin from './img/icon-login.png';
// import iconTel from './img/icon-tel.png';
// import logo from './img/logo.png';
// import iconFacebook from './img/icon-facebook.png';
// import iconInstagram from './img/icon-instagram.png';
// import bannerLine from './img/banner-line.png';