
// jquery
var $ = require('jquery');

$(document).ready(function() {
    //$('.drawer').drawer();
});

//スマホ用　ハンバーガーナビ
$(function() {
    "use strict";

    $("#gNavToggle").on("click", function() {
        $(this).toggleClass("js-active");
        $("#drawer-content").toggleClass("js-open");
    });
    $("#drawer-content a").on("click", function() {
        $("#gNavToggle").removeClass("js-active");
        $("#drawer-content").removeClass("js-open");
    });
});

window.onload = function() {
    scroll_top_effect();
    PageTopAnime();/* スクロールした際の動きの関数を呼ぶ*/

    $(window).scroll(function(){
        scroll_top_effect();
        PageTopAnime();/* スクロールした際の動きの関数を呼ぶ*/
    });

    function scroll_top_effect(){
        $('#header').each(function(){
            var scroll = $(window).scrollTop();
            if (scroll < 200 ){
                $(this).removeClass('scroll');
            } else {
                $(this).addClass('scroll');
            }
        });
    }

    //スクロールした際の動きを関数でまとめる
    function PageTopAnime() {

        var scroll = $(window).scrollTop(); //スクロール値を取得
        if (scroll >= 200){//200pxスクロールしたら
            $('#page-top').removeClass('DownMove');   // DownMoveというクラス名を除去して
            $('#page-top').addClass('UpMove');      // UpMoveというクラス名を追加して出現
        }else{//それ以外は
            if($('#page-top').hasClass('UpMove')){//UpMoveというクラス名が既に付与されていたら
                $('#page-top').removeClass('UpMove'); //  UpMoveというクラス名を除去し
                $('#page-top').addClass('DownMove');  // DownMoveというクラス名を追加して非表示
            }
        }
        
        var wH = window.innerHeight; //画面の高さを取得
        var footerPos =  $('#site-footer').offset().top; //footerの位置を取得
        if(scroll+wH >= (footerPos+10)) {
            var pos = (scroll+wH) - footerPos+10 //スクロールの値＋画面の高さからfooterの位置＋10pxを引いた場所を取得し
            $('#page-top').css('bottom',pos); //#page-topに上記の値をCSSのbottomに直接指定してフッター手前で止まるようにする
            }else{//それ以外は
            if($('#page-top').hasClass('UpMove')){//UpMoveというクラス名がついていたら
                $('#page-top').css('bottom','10px');// 下から10pxの位置にページリンクを指定
            }
        }
    }

    // #page-topをクリックした際の設定
    $('#page-top').click(function () {
        $('body,html').animate({
            scrollTop: 0//ページトップまでスクロール
        }, 500);//ページトップスクロールの速さ。数字が大きいほど遅くなる
        return false;//リンク自体の無効化
    });
};




